import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import { SEO } from "components";
import {
	FeaturedArticle,
	ArticleList,
	ArticlePagination,
} from "components/news";
import { PageHeading } from "components/layout";
import useMenu from "state/useMenu";
/* News Page
============================================================================= */

const NewsPage = ({ data }) => {
	// Config
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	return (
		<>
			<SEO title="News" />
			<ArticleList articles={data.allSanityNewsArticle.nodes} />
		</>
	);

	// Render
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query NewsPageQuery {
		allSanityNewsArticle(sort: { order: DESC, fields: _createdAt }) {
			nodes {
				author {
					name
				}
				image {
					alt
					image {
						asset {
							localFile {
								childImageSharp {
									fluid(maxWidth: 750) {
										src
										srcSet
									}
								}
							}
						}
					}
				}
				title
				_createdAt
			}
		}
	}
`;

/* Export
============================================================================= */

export default NewsPage;

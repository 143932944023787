import React from "react";
import ArticleItem from "./articleItem";
import { List } from "components/layout";

/* Article List
============================================================================= */

const ArticleList = ({ articles }) => {
	// Config

	// Render Articles
	const renderArticles = () => {
		return articles.map((article) => {
			return <ArticleItem article={article} key={article.title} />;
		});
	};

	// Render
	return (
		<List
			title="News"
			grid="2xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12"
			className="padding-bottom"
		>
			{renderArticles()}
		</List>
	);
};

/* Export
============================================================================= */

export default ArticleList;
